import Vue from "vue";
import CommonDataModule from "@/store/modules/commonDataModule";
import {
  Kiosko,
  OrdenDotacion,
  EstatusOrdenDotacion,
  Dotacion,
  FondoCaja,
  DetalleOrdenDotacion,
  TipoDenominacion,
  EstatusDetalleOrdenDotacion,
} from "apd.sistemapagos.models";
import {
  mdiAlertCircleOutline,
  mdiCheckCircleOutline,
  mdiMonitorDashboard,
  mdiPencil,
  mdiChevronDown,
  mdiCashRegister,
  mdiSwapHorizontal,
  mdiCash,
  mdiCurrencyUsd,
  mdiCashPlus,
  mdiCancel,
  mdiPlus,
  mdiClose,
  mdiChevronUp,
} from "@mdi/js";
import dotacionesModule from "@/store/modules/dotacionesModule";
import spinnerModule from "@/store/modules/spinnerModule";
import snackbarModule from "@/store/modules/snackbarModule";
import { Guid } from "guid-typescript";
import { formatISO } from "date-fns";
import intranetModule from "@/store/modules/intranetModule";
import { User } from "apd.intranet.models";
import { AxiosHttpClient, ApiSistemaPagos, ApiErrorResponse } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";
import { format } from "date-fns";

export default Vue.extend({
  name: "ResumenDotaciones",
  components: {
    detalleDotacion: () => import("@/components/dotaciones/detalleDotacion/detalleDotacion.vue"),
  },
  data() {
    return {
      Icons: {
        Activo: mdiSwapHorizontal,
        Cerrado: mdiCheckCircleOutline,
        Cajero: mdiMonitorDashboard,
        Editar: mdiPencil,
        cancelar: mdiCancel,
        Expand: mdiChevronDown,
        Collapse: mdiChevronUp,
        Saldo: mdiCashRegister,
        Nuevas: mdiAlertCircleOutline,
        Cantidad: mdiCashPlus,
        Denominacion: mdiCurrencyUsd,
        TipoDenominacion: mdiCash,
        Remover: mdiCancel,
        Agregar: mdiPlus,
        Close: mdiClose,
      } as Record<string, string>,
      dialog: false,
      dialogTemplate: "",
      dialogDotacion: false,
      titulo: "",
      autoLineWidth: false,
      singleExpand: false,
      expanded: [],
      headers: [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "",
        },
        {
          text: "Usuario Creador",
          align: "center",
          sortable: true,
          value: "idUsuarioCreador",
        },
        {
          text: "Usuario Custodio",
          align: "center",
          sortable: true,
          value: "idUsuarioEjecutor",
        },
        {
          text: "Cajero",
          align: "center",
          sortable: true,
          value: "idKiosko",
        },
        {
          text: "Estatus",
          align: "start",
          sortable: false,
          value: "estatusOrdenDotacion",
        },
        {
          text: "Fecha creación",
          align: "center",
          sortable: true,
          value: "created",
        },
        {
          text: "",
          align: "center",
          sortable: false,
        },
      ],
      cajero: "",
      ordenToModify: "",
      dataTable: [] as OrdenDotacion[],
      saldo: [] as Dotacion[],
      saldoCaja: 0,
      fondoCaja: [] as FondoCaja[],
      diariasNuevas: [] as OrdenDotacion[],
      diariasCerradas: [] as OrdenDotacion[],
      readonly: true as boolean,
      ordenEditar: [] as DetalleOrdenDotacion[],
      dotacionTitle: "",
      billete: 0,
      moneda: 1,
      switchDotaciones: false as boolean,
      fechaActual: format(Date.now(), "dd/MM/yyyy"),
      fechaLabel: "Fecha creación",
      loading: false,
    };
  },
  computed: {
    ordenesDotacionDiarias(): OrdenDotacion[] {
      return dotacionesModule.dotacionesDiarias;
    },
    cajerosList(): Kiosko[] {
      return CommonDataModule.Cajeros;
    },
    usuariosList(): User[] {
      return intranetModule.usuarios;
    },
    estatusOrdenDotacionToString() {
      return (value: number): string => {
        return EstatusOrdenDotacion[value];
      };
    },
    tipoDenominacionToString() {
      return (value: number): string => {
        return TipoDenominacion[value];
      };
    },
    nombreUsuario() {
      return (value: string): string | undefined => {
        const nombre = this.usuariosList.find((e) => e.id === value)?.givenName;
        const apellido = this.usuariosList.find((e) => e.id === value)?.familyName;
        return nombre + " " + apellido;
      };
    },
    getKiosko() {
      return (value: string): string | undefined => {
        return this.cajerosList.find((e) => e.id === value)?.nombrePublico;
      };
    },
    dotacionesUsuarioNuevas() {
      return dotacionesModule.dotacionesUsuario.filter((e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Nueva);
    },
    dotacionesUsuarioCerradas() {
      return dotacionesModule.dotacionesUsuario.filter((e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Aplicada);
    },
    dotacionesUsuarioNuevasDia() {
      return dotacionesModule.OrdenesDotacionUsuarioDiarias.filter(
        (e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Nueva,
      ).sort((a, b) => (a.created > b.created ? -1 : 1));
    },
    dotacionesUsuarioCerradasDia() {
      return dotacionesModule.OrdenesDotacionUsuarioDiarias.filter(
        (e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Aplicada,
      ).sort((a, b) => (a.created > b.created ? -1 : 1));
    },
    dotacionesNuevasKiosko() {
      return dotacionesModule.dotacionesKiosko
        .filter((e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Nueva)
        .sort((a, b) => (a.created > b.created ? -1 : 1));
    },
    dotacionesCerradasKiosko() {
      return dotacionesModule.dotacionesKiosko
        .filter((e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Aplicada)
        .sort((a, b) => (a.created > b.created ? -1 : 1));
    },
    dotacionesNuevasDia() {
      return dotacionesModule.dotacionesDiarias.filter((e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Nueva);
    },
    dotacionesCerradasDia() {
      return dotacionesModule.dotacionesDiarias.filter((e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Aplicada);
    },
    disableActivas() {
      if (!this.switchDotaciones && this.dotacionesUsuarioNuevas.length > 0) {
        return false;
      } else if (this.switchDotaciones && this.dotacionesNuevasKiosko.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    disableCerradas() {
      if (!this.switchDotaciones && this.dotacionesUsuarioCerradas.length > 0) {
        return false;
      } else if (this.switchDotaciones && this.dotacionesCerradasKiosko.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    format() {
      return (value: string): string => {
        if (value != undefined) {
          return format(Date.parse(value), "dd/MM/yyyy");
        } else {
          return "";
        }
      };
    },
  },
  async mounted() {
    this.getDotaciones();
    await CommonDataModule.LoadCajeros();
  },
  methods: {
    async getDotaciones() {
      if (!this.switchDotaciones) {
        await dotacionesModule.LOAD_DOTACION_USUARIO();
      } else {
        await dotacionesModule.LoadDotacionKiosko(this.cajero);
        await dotacionesModule.LOAD_SALDO_CAJA(this.cajero);
        this.saldo = dotacionesModule.saldoCaja;
        this.saldoCaja = this.saldo.reduce(
          (dot, i) => dot + Number(i.fondosCaja?.reduce((fc, b) => fc + Number(b.cantidad * b.denominacion), 0)),
          0,
        );
        await dotacionesModule.LOAD_DOTACIONES_DIARIAS(this.cajero);
      }
    },
    async dotacionesView(estatus: string) {
      await intranetModule.LoadUsuarios();

      if (estatus == "activas") {
        this.headers[5].text = "Fecha Creación";
        this.titulo = "Ordenes dotación activas";
        this.fechaLabel = "Fecha creación";
        if (!this.switchDotaciones) {
          this.dataTable = dotacionesModule.dotacionesUsuario.filter(
            (e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Nueva,
          );
        } else {
          this.dataTable = dotacionesModule.dotacionesKiosko.filter(
            (e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Nueva,
          );
        }
      } else {
        this.headers[5].text = "Fecha Aplicación";
        this.titulo = "Ordenes dotación cerradas";
        this.fechaLabel = "Fecha aplicación";
        if (!this.switchDotaciones) {
          this.dataTable = dotacionesModule.dotacionesUsuario.filter(
            (e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Aplicada,
          );
        } else {
          this.dataTable = dotacionesModule.dotacionesKiosko.filter(
            (e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Aplicada,
          );
        }
      }
      this.dialog = true;
    },
    remove(index: number) {
      this.ordenEditar.splice(index, 1);
    },
    add() {
      this.ordenEditar.push({
        id: Guid.create().toString(),
        created: formatISO(Date.now()),
        updated: formatISO(Date.now()),
        idOrdenDotacion: this.ordenToModify,
        estatusDetalleOrdenDotacion: EstatusDetalleOrdenDotacion.Nueva,
        observaciones: undefined,
        cantidad: 0,
        denominacion: 0,
        tipoDenominacion: TipoDenominacion.Billete,
      });
    },
    modificarDotacion() {
      if (this.dialogTemplate == "editar") {
        this.actualizarDotacion();
      } else {
        this.cancelarDotacion();
      }
    },
    async dialogDotacionMeth(id: string, tipo: string) {
      if (tipo == "editar") {
        this.dialogTemplate = "editar";
        this.ordenToModify = id;
        this.dotacionTitle = "Editar Orden Dotación";
        const ordenEdit = dotacionesModule.dotacionesUsuario.find((e) => e.id === this.ordenToModify);
        if (ordenEdit != undefined) {
          this.ordenEditar = ordenEdit.detallesOrdenDotacion as DetalleOrdenDotacion[];
        }
        this.dialogDotacion = true;
      }
      if (tipo == "cancelar") {
        this.dialogTemplate = "cancelar";
        this.ordenToModify = id;
        this.dotacionTitle = "Cancelar Orden Dotación";
        this.dialogDotacion = true;
      }

      if (tipo == "cerrar") {
        await dotacionesModule.LOAD_DOTACION_USUARIO();
        this.dialogDotacion = false;
      }
    },
    async actualizarDotacion() {
      const clienteSP = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
      clienteSP.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP = new ApiSistemaPagos(clienteSP);

      await apiSP
        .ActualizarOrdenDotacion(this.ordenEditar, this.ordenToModify)
        .then((e) => {
          spinnerModule.Show;
          if (e == true) {
            snackbarModule.ShowSuccess("Orden dotacion actualizada.");
            this.ordenEditar = [];
            this.dialogDotacion = false;
          }
        })
        .catch((error) => {
          const x = error as ApiErrorResponse;
          x.apiError.mensajeUsuario;
          snackbarModule.Show(x.apiError.mensajeUsuario);
        })
        .finally(() => {
          spinnerModule.Hide;
        });
    },
    async cancelarDotacion() {
      spinnerModule.Show;
      const ordenCancel = dotacionesModule.dotacionesUsuario.find((e) => e.id === this.ordenToModify);
      const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
        getUrl(ApiList.SistemaPagos, this.$store.getters.appMode),
      );
      axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

      if (ordenCancel !== undefined) {
        if (ordenCancel.estatusOrdenDotacion != EstatusOrdenDotacion.Aplicada) {
          await apiSP
            .CancelarOrdenDotacion(ordenCancel)
            .then((e) => {
              if (e == true) {
                snackbarModule.ShowSuccess("Orden dotacion cancelada.");

                //this.dialogDotacion = false;
                //this.dialog = false;
              }
            })
            .catch((error) => {
              const x = error as ApiErrorResponse;
              x.apiError.mensajeUsuario;
              snackbarModule.Show(x.apiError.mensajeUsuario);
            });

          await dotacionesModule.LOAD_DOTACION_USUARIO();
          await dotacionesModule.LoadDotacionKiosko(this.cajero);

          if (!this.switchDotaciones) {
            this.dataTable = dotacionesModule.dotacionesUsuario.filter(
              (e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Nueva,
            );
          } else {
            this.dataTable = dotacionesModule.dotacionesKiosko.filter(
              (e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Nueva,
            );
          }
        } else {
          await apiSP
            .CancelarOrdenDotacionAplicada(ordenCancel)
            .then((e) => {
              if (e == true) {
                snackbarModule.ShowSuccess("Orden dotacion cancelada.");
              }
            })
            .catch((error) => {
              const x = error as ApiErrorResponse;
              x.apiError.mensajeUsuario;
              snackbarModule.Show(x.apiError.mensajeUsuario);
            });

          await dotacionesModule.LOAD_DOTACION_USUARIO();
          await dotacionesModule.LoadDotacionKiosko(this.cajero);
          if (!this.switchDotaciones) {
            this.dataTable = dotacionesModule.dotacionesUsuario.filter(
              (e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Aplicada,
            );
          } else {
            this.dataTable = dotacionesModule.dotacionesKiosko.filter(
              (e) => e.estatusOrdenDotacion == EstatusOrdenDotacion.Aplicada,
            );
          }
        }
      }
      this.dialogDotacion = false;
    },
  },
});
