import { Module, VuexModule, Action, Mutation, getModule } from "vuex-module-decorators";
import store from "../";
import snackbarModule from "./snackbarModule";
import spinnerModule from "./spinnerModule";
import { User, FiltroUsuarios } from "apd.intranet.models";
import { AxiosHttpClient, ApiIntranet, ApiErrorResponse } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";

@Module({
  namespaced: true,
  name: "intranetModule",
  store,
  dynamic: true,
})
class IntranetModule extends VuexModule {
  public usuarios: User[] = [];
  public filtroUsuarios: FiltroUsuarios = {
    tipos: [0, 3],
  };

  @Mutation
  public SET_USUARIOS(value: User[]) {
    this.usuarios = value;
  }

  @Action
  public async LoadUsuarios() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.Intranet, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const api: ApiIntranet = new ApiIntranet(axiosHttpClient);

    return await api
      .GetUsuariosFiltro(this.filtroUsuarios)
      .then((e) => {
        this.SET_USUARIOS(e as User[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        snackbarModule.Show(x.apiError.mensajeUsuario);
      })
      .finally(() => {
        spinnerModule.Hide;
      });
  }
}
export default getModule(IntranetModule);
