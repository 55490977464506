import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import { OrdenDotacion, Dotacion, KioskoDispensador } from "apd.sistemapagos.models";
import store from "../";
import spinnerModule from "./spinnerModule";
import { formatISO } from "date-fns";
import snackbarModule from "./snackbarModule";
import { AxiosHttpClient, ApiErrorResponse, ApiSistemaPagos } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";

@Module({
  namespaced: true,
  name: "dotacionesModule",
  store,
  dynamic: true,
})
class DotacionesModule extends VuexModule {
  public dotacionesKiosko: OrdenDotacion[] = [];
  public dotacionesDiarias: OrdenDotacion[] = [];
  public saldoCaja: Dotacion[] = [];
  public dispensador: KioskoDispensador[] = [];
  public dotacionesUsuario: OrdenDotacion[] = [];
  public OrdenesDotacionUsuarioDiarias: OrdenDotacion[] = [];

  @Mutation
  public SET_ORDEN_DOTACION_KIOSKO(data: OrdenDotacion[]) {
    this.dotacionesKiosko = data;
  }
  @Mutation
  public SET_DOTACIONES_DIARIAS(data: OrdenDotacion[]) {
    this.dotacionesDiarias = data;
  }

  @Mutation
  public SET_SALDO_CAJA(data: Dotacion[]) {
    this.saldoCaja = data;
  }

  @Mutation
  public SET_DISPENSADOR(data: KioskoDispensador[]) {
    this.dispensador = data;
  }

  @Mutation
  public SET_DOTACIONES_USUARIO(data: OrdenDotacion[]) {
    this.dotacionesUsuario = data;
  }

  @Mutation
  public SET_DOTACIONES_USUARIO_DIARIAS(data: OrdenDotacion[]) {
    this.OrdenesDotacionUsuarioDiarias = data;
  }

  @Action
  public async LoadDotacionKiosko(idKiosko: string) {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    await apiSP
      .ConsultarOrdenDotacionKiosko(
        idKiosko,
        formatISO(Date.now() - 30 * 24 * 60 * 60 * 1000).substr(0, 10),
        formatISO(Date.now()).substr(0, 10) + "T23:59:59",
      )
      .then((e) => {
        spinnerModule.Show();
        this.SET_ORDEN_DOTACION_KIOSKO(e as OrdenDotacion[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        this.SET_ORDEN_DOTACION_KIOSKO([]);
      })
      .finally(() => {
        spinnerModule.Hide();
      });
  }

  @Action
  public async LOAD_DOTACIONES_DIARIAS(idKiosko: string) {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    await apiSP
      .ConsultarOrdenDotacionKiosko(
        idKiosko,
        formatISO(Date.now()).substr(0, 10) + "T00:00:00",
        formatISO(Date.now()).substr(0, 10) + "T23:59:59",
      )
      .then((e) => {
        spinnerModule.Show();
        this.SET_DOTACIONES_DIARIAS(e as OrdenDotacion[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        this.SET_DOTACIONES_DIARIAS([]);
      })
      .finally(() => {
        spinnerModule.Hide();
      });
  }

  @Action
  public async LOAD_SALDO_CAJA(idKiosko: string) {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    return await apiSP
      .ConsultarDotacionesKiosko(idKiosko)
      .then((e) => {
        spinnerModule.Show;
        this.SET_SALDO_CAJA(e as Dotacion[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        this.SET_SALDO_CAJA([]);
      })
      .finally(() => {
        spinnerModule.Hide;
      });
  }

  @Action
  public async LOAD_DISPENSADOR(idKiosko: string) {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    await apiSP
      .GetKioskoDispensador(idKiosko)
      .then((e) => {
        this.SET_DISPENSADOR(e as KioskoDispensador[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        this.SET_DISPENSADOR([]);
        snackbarModule.Show("No se encontro dotación estandar para este cajero.");
      })
      .finally(() => {
        spinnerModule.Hide;
      });
  }

  @Action
  public async LOAD_DOTACION_USUARIO() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    const idUser = this.context.rootGetters["oidcStore/oidcUser"].sub;

    return await apiSP
      .ConsultarOrdenDotacionUsuarioCreador(
        idUser,
        formatISO(Date.now() - 30 * 24 * 60 * 60 * 1000).substr(0, 10),
        formatISO(Date.now()).substr(0, 10) + "T23:59:59",
      )
      .then((e) => {
        this.SET_DOTACIONES_USUARIO(e as OrdenDotacion[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        this.SET_DOTACIONES_USUARIO([]);
      })
      .finally(() => {
        spinnerModule.Hide;
      });
  }

  @Action
  public async LOAD_DOTACION_USUARIO_DIARIA() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    const idUser = this.context.rootGetters["oidcStore/oidcUser"].sub;

    return await apiSP
      .ConsultarOrdenDotacionUsuarioCreador(
        idUser,
        formatISO(Date.now()).substr(0, 10) + "T00:00:00",
        formatISO(Date.now()).substr(0, 10) + "T23:59:59",
      )
      .then((e) => {
        this.SET_DOTACIONES_USUARIO_DIARIAS(e as OrdenDotacion[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        this.SET_DOTACIONES_USUARIO_DIARIAS([]);
      })
      .finally(() => {
        spinnerModule.Hide;
      });
  }
}
export default getModule(DotacionesModule);
